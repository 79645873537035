#home {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
  
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }
  
  .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
  
    padding: 6rem 2rem 0rem;
  
    @media screen and (min-width: 2000px) {
      padding-top: 8rem;
    }
  
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  
    @media screen and (max-width: 450px) {
      padding: 6rem 1rem 2rem;
    }
  }
  
  .app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  
    margin: 0 2rem;
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }
  }
  
  .app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  
    .badge-cmp,
    .tag-cmp {
      padding: 1rem 2rem;
      background: var(--white-color);
      border-radius: 15px;
      flex-direction: column;
      align-items: flex-start;
      width: auto;
  
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  
    .tag-cmp {
      flex-direction: column;
      margin-top: 1rem;
  
      p {
        width: 100%;
        text-transform: uppercase;
        text-align: right;
      }
    }
  
    span {
      font-size: 2.5rem;
  
      @media screen and (min-width: 2000px) {
        font-size: 5rem;
      }
    }
  
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  
  .app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;
  
    div {
      width: var(--big-bubble);
      height: var(--big-bubble);
      border-radius: 50%;
      margin: 0.25rem;
  
      background: var(--white-color);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  
      img {
        width: 60%;
        height: 60%;
      }
    }
  
    div:nth-child(1) {
      margin: 0.25rem 0.25rem 0.25rem -4rem;
      width: var(--medium-bubble);
      height: var(--medium-bubble);
    }
  
    div:nth-child(2) {
      margin: -1rem 0.25rem 0.25rem 0.25rem;
      width: var(--extra-big-bubble);
      height: var(--extra-big-bubble);
    }
  
    div:nth-child(3) {
        margin: -1.5rem 0.25rem 0.25rem 0.25rem;
        width: var(--small-bubble);
        height: var(--small-bubble);
    }

    div:nth-child(4) {
        margin: 0.25rem 0.25rem 0.25rem 4rem;
        width: var(--big-bubble);
        height: var(--big-bubble);
    }

    div:nth-child(5) {
        margin: 0.25rem 0.25rem 0.25rem 3rem;
        width: var(--medium-bubble);
        height: var(--medium-bubble);
    }

    div:nth-child(6) {
        margin: 1rem 0.25rem 0.25rem -1rem;
        width: var(--big-bubble);
        height: var(--big-bubble);
    }
  
    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            margin: 0.25rem 0.25rem 0.25rem -4rem;
            width: var(--big-bubble);
            height: var(--big-bubble);
          }
        
          div:nth-child(2) {
            margin: -1rem 0.25rem 0.25rem 0.25rem;
            width: var(--extra-big-bubble);
            height: var(--extra-big-bubble);
          }
        
          div:nth-child(3) {
              margin: -1.5rem 0.25rem 0.25rem 0.25rem;
              width: var(--medium-bubble);
              height: var(--medium-bubble);
          }
      
          div:nth-child(4) {
              margin: 0.25rem 0.25rem 0.25rem 4rem;
              width: var(--extra-big-bubble);
              height: var(--extra-big-bubble);
          }
      
          div:nth-child(5) {
              margin: 0.25rem 0.25rem 0.25rem 3rem;
              width: var(--big-bubble);
              height: var(--big-bubble);
          }
      
          div:nth-child(6) {
              margin: 1rem 0.25rem 0.25rem -1rem;
              width: var(--extra-big-bubble);
              height: var(--extra-big-bubble);
          }
    }
  
    @media screen and (max-width: 1200px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;
  
      div {
        margin: 1rem;
      }

      div:nth-child(1) {
        margin: 1rem;
        width: var(--medium-bubble);
        height: var(--medium-bubble);
      }
    
      div:nth-child(2) {
        margin: 1rem;
        width: var(--extra-big-bubble);
        height: var(--extra-big-bubble);
      }
    
      div:nth-child(3) {
        margin: 1rem;
        width: var(--small-bubble);
        height: var(--small-bubble);
      }
  
      div:nth-child(4) {
        margin: 1rem;
        width: var(--big-bubble);
        height: var(--big-bubble);
      }
  
      div:nth-child(5) {
        margin: 1rem;
        width: var(--medium-bubble);
        height: var(--medium-bubble);
      }
  
      div:nth-child(6) {
        margin: 1rem;
        width: var(--big-bubble);
        height: var(--big-bubble);
      }
    }
  }
  
  .app__header-img {
    flex: 1;
    height: 100%;
  
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  
    img {
      width: 100%;
      object-fit: contain;
      z-index: 1;
    }
  
    .overlay_circle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: 90%;
    }
  
    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
}